import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

export const getShoeSizes = (start: number, stop: number, step = 1): number[] =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);

const HEADER_TITLE = `Thank you for interest in Gray Matters! Please leave your name, email address and desired shoe size, and we will contact you with more information.`;
const THANK_YOU_MESSAGE = `Thanks, we're looking into your inquiry and will be in touch!`;
const EMAIL_FORM_ENDPOINT = 'https://getform.io/f/b337f806-8705-4fb8-98db-03d3290dd84d';
const SHOE_SIZES = getShoeSizes(35, 41.5, 0.5);

interface ServerState {
  submitting: boolean;
  status?: {
    ok: boolean,
    msg: string
  } | null;
}

interface Props {
  productTitle?: string;
}

export const ModalHeader = styled.div`
  padding: 40px 30px 0px;
  border-bottom: none;
`;

export const ModalBody = styled.div`
  padding: 10px 30px;
`;

export const ModalFields = styled.div`
  padding: 0 !important;
  width: 95% !important;
`;

export const SubmitStatus = styled.p`
  padding: 20px 0 0;
`;

export const ModalContainer = styled.div`
  @media only screen and (max-width: 992px) {
    position: absolute !important;
    height: 100vh !important;
  }
`;

export const ProductInquiryForm = ({ productTitle }: Props) => {
  const [serverState, setServerState] = useState<ServerState>({
    submitting: false,
    status: null
  });

  const handleServerResponse = (ok: boolean, msg: string, form: any) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: 'post',
      url: EMAIL_FORM_ENDPOINT,
      data: new FormData(form)
    })
      .then((r) => {
        handleServerResponse(true, THANK_YOU_MESSAGE, form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <ModalContainer className="modal fade" id="myModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <ModalHeader className="modal-header">
            <h3>{productTitle}</h3>
            <button type="button" className="close" data-dismiss="modal">
              ×
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="popup-form">
              <p>{HEADER_TITLE}</p>
              <div className="contact-form clearfix">
                <div>
                  <ModalFields className="col-md-8 mt-5">
                    <form onSubmit={handleOnSubmit}>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="emailAddress"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputName">First Name</label>
                        <input
                          type="text"
                          name="first-name"
                          className="form-control"
                          id="firstName"
                          placeholder="First Name"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputName">Last Name</label>
                        <input
                          type="text"
                          name="last-name"
                          className="form-control"
                          id="lastName"
                          placeholder="Last Name"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="shoeSize">Shoe Size:</label>
                        <select
                          id="shoeSize"
                          name="shoeSize"
                          className="form-control product-form__variants no-js">
                          {SHOE_SIZES.map((shoeSize: number) => (
                            <option key={`shoe-size-${shoeSize}`} value={shoeSize}>
                              {shoeSize}
                            </option>
                          ))}
                        </select>
                      </div>
                      <input
                        type="hidden"
                        id="productTitle"
                        name="productTitle"
                        value={productTitle}
                      />
                      <button
                        type="submit"
                        className="dark-btn product_sold_out_btn"
                        disabled={serverState.submitting}>
                        Send
                      </button>
                      {serverState.status && (
                        <SubmitStatus className={!serverState.status.ok ? 'errorMsg' : ''}>
                          {serverState.status.msg}
                        </SubmitStatus>
                      )}
                    </form>
                  </ModalFields>
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ProductInquiryForm;
