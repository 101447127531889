import React, { ReactElement } from 'react';

import { Video } from 'component/Video';

import {
  ModuleVideoContainer,
  ModuleVideoContainerVideo,
  ModuleVideoContainerTextBlock,
  ModuleVideoContainerTextBlockRowContainer,
  ModuleVideoContainerTextBlockRow,
  ModuleVideoContainerTextBlockRowContent,
  ModuleVideoContainerTextBlockRowHeader,
  ModuleVideoText,
  ModuleVideoDescription
} from './element';

interface Props {
  layout?: string;
  header?: string;
  description?: string;
  videoId: string;
  showVolumeControls?: boolean;
}

export const VideoModule = ({
  layout = 'fullbleed',
  header,
  description,
  videoId,
  showVolumeControls
}: Props): ReactElement => (
  <ModuleVideoContainer>
    <ModuleVideoContainerTextBlock>
      <ModuleVideoContainerTextBlockRowContainer>
        <ModuleVideoContainerTextBlockRow>
          <ModuleVideoContainerTextBlockRowContent>
            <ModuleVideoContainerTextBlockRowHeader>
              <ModuleVideoText>{header}</ModuleVideoText>
            </ModuleVideoContainerTextBlockRowHeader>
            <ModuleVideoDescription>
              <ModuleVideoText>{description}</ModuleVideoText>
            </ModuleVideoDescription>
          </ModuleVideoContainerTextBlockRowContent>
        </ModuleVideoContainerTextBlockRow>
      </ModuleVideoContainerTextBlockRowContainer>
    </ModuleVideoContainerTextBlock>
    <ModuleVideoContainerVideo>
      <Video videoId={videoId} showVolumeControls={showVolumeControls} />
      {/* <iframe
        title="video_vimeo"
        src={videoId}
        frameBorder="0"
        allowFullScreen
        allow="autoplay"
        data-muted="true"></iframe> */}
    </ModuleVideoContainerVideo>
  </ModuleVideoContainer>
);

export default VideoModule;
