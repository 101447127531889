import React, { ReactElement, useState } from 'react';
import ReactPlayer from 'react-player';
import { IoMdVolumeMute, IoMdVolumeHigh } from 'react-icons/io';

import { VideoContainer, VolumeContainer } from './element';

import { getVideoUrl } from 'lib/video';

interface Props {
  videoId: string;
  showVolumeControls?: boolean;
}

export const Video = ({ videoId, showVolumeControls }: Props): ReactElement => {
  const [volumeOn, setVolumeOn] = useState(false);

  const videoUrl = getVideoUrl({ videoId, videoType: 'vimeo' });

  return (
    <VideoContainer onClick={() => setVolumeOn(!volumeOn)}>
      <ReactPlayer
        url={videoUrl}
        playing
        muted
        loop
        playsinline
        controls={false}
        showTitle={false}
        background
        width="100%"
        height="100%"
        volume={volumeOn ? 0.3 : 0}
        transparent
      />
      {showVolumeControls && (
        <VolumeContainer>{volumeOn ? <IoMdVolumeMute /> : <IoMdVolumeHigh />}</VolumeContainer>
      )}
    </VideoContainer>
  );
};

export default Video;
