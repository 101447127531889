import styled from 'styled-components';

export const ModuleVideoContainer = styled.div`
  padding-top: 0rem;
`;

export const ModuleVideoContainerVideo = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100%;
  background: white;
`;

export const ModuleVideoContainerTextBlock = styled.div``;

export const ModuleVideoContainerTextBlockRowContainer = styled.div``;

export const ModuleVideoContainerTextBlockRow = styled.div``;

export const ModuleVideoContainerTextBlockRowContent = styled.div``;

export const ModuleVideoContainerTextBlockRowHeader = styled.h3``;

export const ModuleVideoText = styled.span``;

export const ModuleVideoDescription = styled.p``;
