import { get } from 'lodash';

export const VIDEO_URL_PREFIXES = {
  vimeo: `https://vimeo.com`
};

export const getVideoUrlPrefix = ({ videoType = 'vimeo' }: { videoType: string }): string =>
  get(VIDEO_URL_PREFIXES, videoType);

export const getVideoUrl = ({
  videoType,
  videoId = 'vimeo'
}: {
  videoType: string,
  videoId: string
}): string => [getVideoUrlPrefix({ videoType }), videoId].join('/');
