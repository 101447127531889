import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { compact, kebabCase, startCase } from 'lodash';
import { graphql, Link } from 'gatsby';
import Markdown from 'react-markdown';
import { useLightbox, SRLWrapper } from 'simple-react-lightbox';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { createSrcSetImages, SRCSET_SIZES } from 'lib/image';
import { VideoModule } from 'module/VideoModule';
import { ProductInquiryForm } from 'component/ProductInquiryForm';

const pause = (_: number) => new Promise((resolve) => setTimeout(resolve, _));

const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const MobileProductThumbnailWrapper = styled.div`
  @media only screen and (max-width: 767px) {
    max-height: 68vh;
    overflow: scroll;
    position: relative;
  }
`;

const MobileProductThumbnailWrapperDownArrowElement = styled.div`
  @media only screen and (max-width: 767px) {
    transform: rotate(-90deg) translate(-50%, 0);
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;

    ::before {
      content: '';
      transform-origin: 0 100%;
      transform: rotate(55deg);

      transition: background-color 0.2s ease-out;
      content: '';
      position: absolute;
      left: 25%;
      right: 25%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.4);
      top: 50%;
    }

    ::after {
      content: '';
      transform-origin: 0 0;
      transform: rotate(-55deg);
      transition: background-color 0.2s ease-out;
      content: '';
      position: absolute;
      left: 25%;
      right: 25%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.4);
      top: 50%;
    }
  }
`;

export const MobileProductThumbnailWrapperDownArrowWrapper = styled.div`
  z-index: 99999999;
  width: 100%;
  top: 56vh;
  position: absolute;
  vertical-align: middle;
`;

export const MobileProductThumbnailWrapperDownArrow = (): any => (
  <MobileProductThumbnailWrapperDownArrowWrapper>
    <MobileProductThumbnailWrapperDownArrowElement />
  </MobileProductThumbnailWrapperDownArrowWrapper>
);

const lightboxOptions = {
  settings: {
    overlayColor: 'rgb(255,255,255)',
    autoplaySpeed: 1500,
    transitionSpeed: 400
  },
  buttons: {
    backgroundColor: '#ffffff',
    iconColor: 'rgba(0, 0, 0, 0.4)',
    showThumbnailsButton: false,
    showFullscreenButton: false,
    showAutoplayButton: false,
    showDownloadButton: false
  },
  thumbnails: {
    showThumbnails: false
  }
};

/* eslint-disable prettier/prettier */
const setSRLElementHeight = () => {
  setTimeout(() => {
    const srlElementContainer: any = document.querySelector(
      '#SRLLightbox > div > div.SRLContainer > div.SRLElementContainer'
    );
    const srlElementWrapper: any = document.querySelector(
      '#SRLLightbox > div > div.SRLContainer > div.SRLElementContainer > div'
    );
    if (srlElementContainer && srlElementWrapper) {
      srlElementWrapper.style.height = '100%';
      srlElementWrapper.style.transition = 'height 400ms ease-in-out';
      srlElementContainer.style.height = '100vh';
      srlElementContainer.style.transition = 'height 400ms ease-in-out';
    }
  }, 410);
};

const lightboxCallbacks = {
  onSlideChange: () => setSRLElementHeight(),
  onLightboxOpened: () => setSRLElementHeight()
};

const ProductContainer = styled.main`
  transition-delay: 100ms;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
  transition-property: all;
  transform: translateY(1rem);
  opacity: ${({ show = false }: { show?: boolean }): string => (show ? `1` : `0`)};
`;

const MobileProductThumbnail = ({
  image,
  title,
  onClickHandler
}: {
  image: string,
  title: string,
  onClickHandler: any
}) => (
  <li
    className="grid__item medium-up--one-quarter product-single__thumbnails-item js product_thumb_img_all"
    style={typeof window !== 'undefined' && window.innerWidth < 768 ? { width: '100vw' } : {}}>
    <span
      style={{ paddingTop: '0' }}
      onClick={() => onClickHandler({ image })}
      className="text-link product-single__thumbnail product-single__thumbnail--new-product-template active-thumb"
      data-thumbnail-id="14845084729449"
      aria-current="true">
      <img
        className="product-single__thumbnail-image"
        src={image}
        alt={`Load image into Gallery viewer, ${title}`}
      />
    </span>
  </li>
);

const ProductThumbnail = ({
  image,
  title,
  onClickHandler
}: {
  image: string,
  title: string,
  onClickHandler: any
}) => (
  <li
    style={{ cursor: 'pointer' }}
    className="grid__item medium-up--one-quarter product-single__thumbnails-item js product_thumb_first_img1 product_thumb_img_all">
    <span
      onClick={() => onClickHandler({ image })}
      className="text-link product-single__thumbnail product-single__thumbnail--new-product-template active-thumb"
      data-thumbnail-id="14845084729449"
      aria-current="true">
      <img
        className="product-single__thumbnail-image"
        src={image}
        alt={`Load image into Gallery viewer, ${title}`}
      />
    </span>
  </li>
);

export const ProductSizes = ({ sizes }: { sizes: string }) => (
  <>
    {sizes.split(',').map((size: string, idx: number) => (
      <option key={`option-size-${idx}`} data-label={size} value={idx}>
        {size}
      </option>
    ))}
  </>
);

const ProductImageContainer = styled.div`
  transition: all 300ms ease-in-out;
  opacity: ${({ toggled = false }: { toggled?: boolean }) => (toggled ? 1 : 0)};
`;

const ProductImage = ({
  image,
  title,
  idx,
  selectedImage,
  onClickHandler,
  imageTransitionToggle
}: {
  image: string,
  title: string,
  idx: number,
  selectedImage: string,
  onClickHandler: any,
  imageTransitionToggle: boolean
}) => {
  const srcSetImages = createSrcSetImages({ image });

  return (
    <ProductImageContainer
      toggled={imageTransitionToggle}
      id="FeaturedImageZoom-new-product-template-14845084729449-wrapper"
      className="product-single__photo-wrapper js product_main_image_left">
      <div
        id="FeaturedImageZoom-new-product-template-14845084729449"
        style={{ paddingTop: '100.0%' }}
        className={`product-single__photo product-single__photo--has-thumbnails ${
          selectedImage && selectedImage === image ? '' : 'hide'
        }`}
        data-image-id="14845084729449">
        <img
          id="FeaturedImage-new-product-template-14845084729449"
          className="feature-row__image product-featured-img lazyautosizes lazyloaded"
          src={image}
          data-widths={SRCSET_SIZES}
          data-aspectratio="1.0"
          data-sizes="auto"
          tabIndex={-1}
          alt={title}
          onClick={() => onClickHandler(idx)}
          data-srcset={srcSetImages}
          sizes="710px"
          srcSet={srcSetImages}
        />
      </div>
    </ProductImageContainer>
  );
};

const ProductDescription = ({ description }: { description?: string }) => (
  <div className="product-description" style={{ marginBottom: '2.5rem' }}>
    <div style={{ marginTop: '2.5rem', marginBottom: '2.5rem' }}>
      <Markdown source={description} escapeHtml={false} />
    </div>
  </div>
);

const ProductImages = ({
  Title,
  MainImage,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  selectedImage,
  onClickHandler
}: {
  Title: string,
  MainImage: string,
  Image1: string,
  Image2: string,
  Image3: string,
  Image4: string,
  Image5: string,
  Image6: string,
  selectedImage: string,
  onClickHandler: any
}) => {
  const images = [MainImage, Image1, Image2, Image3, Image4, Image5, Image6];
  const { openLightbox } = useLightbox();

  const [imageTransitionToggle, setImageTransitionToggle] = useState(true);
  const [delayedTransitionImage, setDelayedTransitionImage] = useState(MainImage);

  const previousImage: string = usePrevious(delayedTransitionImage);

  const setDelayedToggleTransition = async (delay: number, state: boolean, image: string) => {
    await pause(delay);
    await setDelayedTransitionImage(image);
    await setImageTransitionToggle(state);
  };

  const toggleTransition = async (previousImage: string) => {
    setDelayedToggleTransition(0, false, previousImage);
    setDelayedToggleTransition(300, true, selectedImage);
  };

  useEffect(() => {
    toggleTransition(previousImage);
  }, [selectedImage]);

  console.log({ imageTransitionToggle });

  return (
    <>
      <SRLWrapper
        callbacks={lightboxCallbacks}
        images={compact(images).map((image: any) => ({ src: image }))}
        options={lightboxOptions}
      />

      <div
        className="col-md-6 col-sm-6 product_left_img_sec"
        data-current-image={selectedImage}
        style={{ position: 'relative' }}>
        <div className="product_wishlist_button"></div>

        <div className="featured-image hes-gallery">
          {images.map(
            (image: any, idx: number) =>
              image && (
                <ProductImage
                  key={`product-image-${idx}`}
                  imageTransitionToggle={imageTransitionToggle}
                  title={startCase(Title)}
                  image={image}
                  idx={idx}
                  selectedImage={delayedTransitionImage}
                  onClickHandler={openLightbox}
                />
              )
          )}
        </div>
        <div className="product_thumb_new_outer desktop-thumbnails hide-xs">
          <ul
            className="grid grid--uniform product-single__thumbnails product-single__thumbnails-new-product-template moble_slick_new"
            style={{ flexFlow: 'column wrap' }}>
            {images.map(
              (image: string, idx: number) =>
                image && (
                  <ProductThumbnail
                    key={`product-thumbnail-${idx}`}
                    image={image}
                    title={startCase(Title)}
                    onClickHandler={onClickHandler}
                  />
                )
            )}
          </ul>
        </div>
        <MobileProductThumbnailWrapperDownArrow />

        <MobileProductThumbnailWrapper className="product_thumb_new_outer thumbnails-wrapper show-xs thumbnails-slider--active">
          <ul
            id="has-galler-custom"
            className="grid grid--uniform product-single__thumbnails product-single__thumbnails-new-product-template moble_slick_new hes-gallery">
            {images.map(
              (image: string, idx: number) =>
                image && (
                  <MobileProductThumbnail
                    key={`product-thumbnail-${idx}`}
                    image={image}
                    title={startCase(Title)}
                    onClickHandler={onClickHandler}
                  />
                )
            )}
          </ul>
        </MobileProductThumbnailWrapper>
      </div>
    </>
  );
};

interface DetailSectionProps {
  sku?: string;
  title?: string;
  price?: string;
  priceLabel?: string;
  description?: string;
  isVip?: boolean;
}

const ProductDetailSection = ({
  sku,
  title,
  price,
  priceLabel,
  description,
  isVip
}: DetailSectionProps) => (
  <div className="col-md-6 col-sm-6 product_sec_right">
    <div className="details">
      <h1 className="product-title">{sku}</h1>
      <h1 className="product-title">{title}</h1>
      <h4 className="price">
        <dl className="price" data-price="">
          <div className="price__regular">
            <dd>
              <span className="price-item price-item--regular" data-regular-price="">
                <span className="money" style={{ color: '#000' }}>
                  {priceLabel} {price}
                </span>
              </span>
            </dd>
          </div>
        </dl>
      </h4>

      <ProductDescription description={description} />
      {isVip && (
        <>
          <button
            id="sold_out_btn"
            className="dark-btn product_sold_out_btn"
            type="button"
            data-toggle="modal"
            data-target="#myModal">
            <span>INQUIRE</span>
          </button>
          <ProductInquiryForm productTitle={title} />
        </>
      )}
    </div>
  </div>
);

interface ProductBreadcrumbNavProps {
  collection: string;
  title: string;
}

export const ProductBreadcrumbNav = ({ collection, title }: ProductBreadcrumbNavProps) => (
  <nav className="breadcrumbs" role="navigation" aria-label="breadcrumbs">
    <Link to="/" title="Home">
      Home
    </Link>

    <span aria-hidden="true" style={{ marginLeft: '.25rem', marginRight: '.25rem' }}>
      &gt;
    </span>
    <Link to={`/collections/${kebabCase(collection)}`} title="">
      {new RegExp(/([0-9])\w+/).test(collection) ? collection : startCase(kebabCase(collection))}
    </Link>

    <span aria-hidden="true" style={{ marginLeft: '.25rem', marginRight: '.25rem' }}>
      &gt;
    </span>
    <span>{startCase(title)}</span>
  </nav>
);

export const PageTemplate = ({ data: { productsCsv }, isVip = false }: any): ReactElement => {
  const {
    Collection,
    Description,
    SKU,
    Title,
    RetailPrice,
    WholesalePrice,
    MainImage,
    VideoHeader,
    VideoDescription,
    VideoId
  } = productsCsv;

  const [selectedImage, setSelectedImage] = useState(MainImage);
  const [ref, inView] = useInView({ triggerOnce: true });

  // flip price to retail if we're a VIP
  const price = isVip ? RetailPrice : WholesalePrice;

  const priceLabel = isVip ? 'Retail Price - ' : 'Wholesale price - ';

  return (
    <ProductContainer
      ref={ref}
      show={inView}
      className="main-content1 js-focus-hidden"
      id="MainContent"
      role="main"
      tabIndex={-1}>
      <ProductBreadcrumbNav collection={Collection} title={Title} />

      <div id="shopify-section-new-product-template" className="shopify-section">
        <section id="single-main">
          <div
            className="container-fluid pd-0"
            id="ProductSection-new-product-template"
            data-section-id="new-product-template"
            data-section-type="product"
            data-enable-history-state="true">
            <div className="custom-row row">
              <ProductImages
                {...productsCsv}
                selectedImage={selectedImage}
                onClickHandler={({ image }: { image: string }) => setSelectedImage(image)}
              />

              <ProductDetailSection
                sku={SKU}
                title={Title}
                price={price}
                priceLabel={priceLabel}
                description={Description}
                isVip={isVip}
              />
            </div>
            {VideoId && (
              <VideoModule header={VideoHeader} description={VideoDescription} videoId={VideoId} />
            )}
          </div>
        </section>
      </div>
    </ProductContainer>
  );
};

export const pageQuery = graphql`
  query pageQuery($handle: String!) {
    productsCsv(Handle: { eq: $handle }) {
      Barcode
      Handle
      Description
      Published
      SKU
      Sizes
      Tags
      Title
      Collection
      WholesaleOnly
      RetailPrice
      WholesalePrice
      MainImage
      Image1
      Image2
      Image3
      Image4
      Image5
      Image6
      VideoHeader
      VideoDescription
      VideoId
      id
    }
  }
`;

export default PageTemplate;
