import styled, { keyframes } from 'styled-components';

export const VideoContainer = styled.div`
  --video--width: 950;
  --video--height: 540;
  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
  overflow: hidden;
  background: #fff;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const fadeInOutAnimation = keyframes`
  0% {
    opacity: 0.5;
  }
  33% {
    opacity: 0.2;
  }
  66% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.5;
  }
`;

export const VolumeContainer = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;
  opacity: 1;
  transition: all 175ms ease-in-out;
  :hover {
    cursor: pointer;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: #fff;
  }
`;
